import React from 'react'
import { Link, useStaticQuery, graphql, withPrefix } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import { ButtonLink } from '../elements'
import { NonceImg } from '../img'

const ZonesListWrapper = styled.div`
  padding-bottom: 80px;
  @media (max-width: 575px) {
    padding-bottom: 30px;
  }
`

const ZonesListContainer = styled(Container)``

const ZonesListRowWrapper = styled(Row)`
  justify-content: center;
  padding-left: 8%;
  padding-right: 8%;
`

const ZonesListItem = styled(Col)`
  position: relative;
  text-align: center;
  padding-left: 2.4%;
  padding-right: 2.4%;
  padding-bottom: 55px;
  margin-bottom: 20px;
`

const ZonesListItemImageWrapper = styled.div`
  margin-bottom: 30px;
`

const ZonesListItemDescWrapper = styled.div`
  font-size: 16px;
  font-weight: 300;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 14px;
  }
`

const ZonesListItemTitle = styled.h2`
  font-size: 24px;
  font-weight: 700;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 20px;
  }
`

const ZonesListItemButtonWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
`

const ZonesListItemButton = styled(ButtonLink)``

const ZonesList = (props) => {
  const data = useStaticQuery(graphql`
    query {
      zonesListImage1: file(relativePath: { eq: "zone/zone-card-image1.png" }) {
        childImageSharp {
          fluid(maxWidth: 304) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      zonesListImage2: file(relativePath: { eq: "zone/zone-card-image2.png" }) {
        childImageSharp {
          fluid(maxWidth: 304) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      zonesListImage3: file(relativePath: { eq: "zone/zone-card-image3.png" }) {
        childImageSharp {
          fluid(maxWidth: 304) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <ZonesListWrapper>
      <ZonesListContainer>
        <ZonesListRowWrapper>
          <ZonesListItem xs={12} md={6} lg={4}>
            <ZonesListItemImageWrapper>
              <NonceImg
                fluid={data.zonesListImage1.childImageSharp.fluid}
                alt="Misja samodzielność"
              />
            </ZonesListItemImageWrapper>
            <ZonesListItemDescWrapper>
              <ZonesListItemTitle>Misja samodzielność</ZonesListItemTitle>
              <p>Zalicz misje i zostań Samodzielniakiem!</p>
            </ZonesListItemDescWrapper>
            <ZonesListItemButtonWrapper>
              <ZonesListItemButton
                href={withPrefix('/game')}
                target="_blank"
                title="Zagraj"
              >
                Zagraj
              </ZonesListItemButton>
            </ZonesListItemButtonWrapper>
          </ZonesListItem>
          <ZonesListItem xs={12} md={6} lg={4}>
            <ZonesListItemImageWrapper>
              <NonceImg
                fluid={data.zonesListImage2.childImageSharp.fluid}
                alt="Słowniczek bankosłówek"
              />
            </ZonesListItemImageWrapper>
            <ZonesListItemDescWrapper>
              <ZonesListItemTitle>Słowniczek bankosłówek</ZonesListItemTitle>
              <p>Rozgryź trudne bankowe słówka.</p>
            </ZonesListItemDescWrapper>
            <ZonesListItemButtonWrapper>
              <ZonesListItemButton as={Link} to="/slownik" title="Więcej">
                Zagraj
              </ZonesListItemButton>
            </ZonesListItemButtonWrapper>
          </ZonesListItem>
          <ZonesListItem xs={12} md={6} lg={4}>
            <ZonesListItemImageWrapper>
              <NonceImg
                fluid={data.zonesListImage3.childImageSharp.fluid}
                alt="Podejmij wyzwanie"
              />
            </ZonesListItemImageWrapper>
            <ZonesListItemDescWrapper>
              <ZonesListItemTitle>Podejmij wyzwanie</ZonesListItemTitle>
              <p>Zaproś do quizu znajomych i rodziców</p>
            </ZonesListItemDescWrapper>
            <ZonesListItemButtonWrapper>
              <ZonesListItemButton
                href={withPrefix('/quiz')}
                target="_blank"
                title="Zagraj"
              >
                Zagraj
              </ZonesListItemButton>
            </ZonesListItemButtonWrapper>
          </ZonesListItem>
        </ZonesListRowWrapper>
      </ZonesListContainer>
    </ZonesListWrapper>
  )
}

export default ZonesList
