import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import { ButtonLink } from '../elements'

import welcomeDesktopBG from '../../images/zone/welcome-bg.svg'
import welcomeMobileBG from '../../images/zone/welcome-bg_mobile.svg'

const WelcomeWrapper = styled.div`
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 40px;
`

const WelcomeContainer = styled(Container)``

const WelcomeRowWrapper = styled(Row)`
  padding-left: 3.5%;
  padding-right: 3.5%;
`

const WelcomeInnerWrapper = styled.div`
  background-image: url(${welcomeDesktopBG});
  background-repeat: no-repeat;
  background-position: top right;
  background-size: auto 100%;
  padding-top: 4%;
  padding-left: 4%;
  padding-bottom: 10%;
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 0;
    padding-left: 15px;
    padding-bottom: 20px;
  }
  @media (max-width: 767px) {
    background-image: url(${welcomeMobileBG});
    background-position: bottom center;
    background-size: 100% auto;
    padding-top: 20px;
    padding-bottom: 250px;
    margin-top: 0;
  }
  @media (max-width: 575px) {
    background-color: ${(props) => props.theme.colorWhite};
    padding-top: 0;
    padding-bottom: 300px;
    margin-top: 50px;
  }
  @media (max-width: 420px) {
    padding-bottom: 220px;
  }
  @media (max-width: 400px) {
    padding-bottom: 190px;
  }
  @media (max-width: 340px) {
    padding-bottom: 150px;
  }
`

const WelcomeTitle = styled.h1`
  position: relative;
  font-family: ${(props) => props.theme.fontBNPPSansCondensed};
  font-size: 48px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 60px;
  @media (max-width: 767px) {
    font-size: 30px;
    margin-bottom: 50px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 42px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 40px;
  }
  @media (max-width: 575px) {
    font-size: 34px;
    margin-bottom: 30px;
    transform: translateY(-200%);
  }
  span {
    color: ${(props) => props.theme.colorGreenLight};
  }
`

const WelcomeDescWrapper = styled(Col)`
  line-height: 1;
  p {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 15px;
    @media (min-width: 768px) {
      padding-right: 20%;
    }
    @media (max-width: 767px) {
      font-size: 14px;
      padding-right: 40%;
    }
    @media (max-width: 575px) {
      font-size: 15px;
      font-weight: 400;
      padding-right: 45%;
      margin-top: -30px;
    }
    br {
      @media (max-width: 767px) {
        display: none;
      }
    }
  }
`

export const WelcomeDescLink = styled(ButtonLink)`
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 25px;
  @media (max-width: 767px) {
    display: none;
  }
  @media (max-width: 575px) {
    width: 100%;
    margin-bottom: 0;
  }
  &:hover,
  &:focus {
    &:before {
      right: 20px;
    }
  }
  &:before {
    right: 25px;
  }
`

const Welcome = (props) => {
  return (
    <WelcomeWrapper>
      <WelcomeContainer>
        <WelcomeRowWrapper>
          <WelcomeInnerWrapper>
            <Col xs={12}>
              <WelcomeTitle>
                Super, <span>że{'\u00A0'}wpadłeś!</span>
              </WelcomeTitle>
            </Col>
            <WelcomeDescWrapper xs={12} md={6}>
              <p>
                Samodzielność jest fajna. A nasze gry, quizy, bankosłówka oraz
                Benia, Benek i Kaczka Podpowiadaczka pomogą Ci ją ogarnąć!
              </p>
            </WelcomeDescWrapper>
            <Col xs={12}>
              <WelcomeDescLink
                as={Link}
                to="/misjasamodzielnosc"
                title="Dowiedz się więcej"
              >
                Dowiedz się więcej
              </WelcomeDescLink>
            </Col>
          </WelcomeInnerWrapper>
        </WelcomeRowWrapper>
      </WelcomeContainer>
    </WelcomeWrapper>
  )
}

export default Welcome
