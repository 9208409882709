import React from 'react'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Welcome from '../components/children/welcome'
import ZonesList from '../components/children/zones-list'

const ZonePageWrapper = styled.div`
  /* background-color: #f8f8f8; */
  background: rgb(221, 241, 234);
  background: linear-gradient(
    -50deg,
    rgba(221, 241, 234, 1) 0%,
    rgba(255, 255, 255, 1) 70%
  );
  @media (max-width: 767px) {
    overflow: hidden;
  }
  @media (max-width: 575px) {
    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 30%,
      rgba(217, 239, 231, 1) 70%
    );
  }
`

const ZonePageInnerWrapper = styled.div`
  background: rgb(244, 242, 247);
  background: linear-gradient(
    50deg,
    rgba(244, 242, 247, 1) 0%,
    rgba(255, 255, 255, 0.35) 50%
  );
  @media (max-width: 575px) {
    background: linear-gradient(
      50deg,
      rgba(244, 242, 247, 1) 0%,
      rgba(255, 255, 255, 0.35) 20%
    );
  }
`

const ZonePage = ({ location }) => {
  return (
    <>
      <SEO title="Strefa dziecka" />
      <ZonePageWrapper>
        <ZonePageInnerWrapper>
          <Layout location={location}>
            <Welcome />
            <ZonesList />
          </Layout>
        </ZonePageInnerWrapper>
      </ZonePageWrapper>
    </>
  )
}

export default ZonePage
